import React from "react";
import Helmet from "react-helmet";

import "../assets/scss/main.scss";
import "../assets/scss/appstore.scss";

import logo from '../assets/images/wuxing2048/logo.png'

import fiveElement from '../assets/images/wuxing2048/five-elements.png'
import instruction  from '../assets/images/wuxing2048/instruction.png'

class AppPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading: 'is-loading'
        }
      }
  
      componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 100);
      }
  
      componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }
          
    render() {
        const { children } = this.props;
        const siteTitle = "WuXing 2048";

        return (
        <div className={`body ${this.state.loading}`}>

            <Helmet title={siteTitle} />

            <section id="footer">
                <div className="inner">
                    <span><img src={logo} alt="" width="120px" height="120px"/></span>
                    <h1><strong>WuXing 2048</strong></h1>
                    <p>WuXing 2048 is a variant of 2048 by adding WuXing (五行/five elements) concept.</p>
                </div>


                    <div className="grid-wrapper">
                        <div className="col-8">
                            <header className="major">
                                <h2>Play Instruction</h2>
                            </header>
                            <p>WuXing 2048 is a variant of single-player sliding block puzzle game 2048. It added WuXing (五行/five elements)  concept to the classic rules of 2048. Due to interaction (i.e. generating生 and overcoming剋)  between five elements (Metal, Wood, Water, Fire, Earth), it make the classic 2048 game more challenge and interesting.</p>
                            <p>The game is played on a gray 4×4 grid. Each tile has its own value and element property (Metal, Wood, Water, Fire, Earth). Every turn, a new tile will randomly appear in an empty spot on the board with one of element and a value of either 2 or 4.</p>
                            <p>The tiles can slide smoothly when a player swipe on screen. Tiles slide as far as possible in the chosen direction until they are stopped by either another tile or the edge of the grid. If two tiles of the same number collide while moving, they may able to merge by following rules:</p>
                        </div>
                        <div className="col-4">
                            <span className="image fit"><img src={fiveElement} alt="" /></span>
                        </div>
<div className="col-8">

<h4>Same(相同)</h4>
<ul><li>If two tiles collide with same element and same value, then they will merge together with same element. The value become sum of two tiles.</li></ul>

<h4>Generating(相生)</h4>
<ul><li>When a tile with element A collide with a element B and both have same value, then if element A has a generating relationship with element B, then they will merge to a new tile with element B. The value of new tile will be double of sum of two titles.</li></ul>

<h4>Overcoming(相剋)</h4>
<ul><li>When a tile with element A collide with a element B and both have same value. If element A has a overcoming relationship with element B, then the result will be replaced by element A with same value.</li></ul> 
</div>
                        <div className="col-4">
                            <span className="image fit"><img src={instruction} alt="" /></span>
                        </div>
                        <div className="col-12">
<h2>Goal</h2>            
<p>The player should try to use the generating as possible and avoid overcoming to merge tile in order to maximize the source.<br/>
The game is won when a tile with a value of 2048 appears on the board.  When the player has no legal moves (there are no empty spaces and no adjacent tiles with the same value), the game ends.</p>
</div>

</div>

     

                <ul className="icons">
                    <li>
                        <a className="icon appStoreButton" href='https://play.google.com/store/apps/details?id=com.sumlook.dictationmate&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        <a className="icon appStoreButton" href='https://apps.apple.com/us/app/mawanguide/id1547183630'><img alt='Get it on Apple App Store' src="/images/apple-app-store.png"/></a>
                        <a className="icon appStoreButton" href='https://appgallery.cloud.huawei.com/ag/n/app/C103004369?channelId=sumlook.com&referrer=sumlook.com&id=663d41d960e84159849e6d5df2db19e7&s=EAF31EB2059662629795AD4A0017A4A08755A685EDB5D7F0DF23B96C527AFCB0&detailType=0&v='><img alt='Get it on Huawei AppGallery' src="/images/huawei-appgallery.png"/></a>
                    </li> 
                </ul>
                <ul className="copyright">
                    <li>© Sumlook Production</li>
                </ul>
            </section>

        </div>
        );
    }
}

export default AppPage;